import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Map from '../components/Map'
import Share from '../components/Share'

import { themeStyles } from '../utils/theme'

class MapComponent extends React.Component {
  getEsriFeatures() {
    let posts = get(this, 'props.data.allMarkdownRemark.edges') || []
    posts = posts.filter(post => post.node.frontmatter.published)
    const features = posts.map(({ node }) => {
      const title = get(node, 'frontmatter.title');
      const thumbnailImage = get(node, 'frontmatter.featuredImage.childImageSharp.fixed.src')
      const location = JSON.parse(get(node, 'frontmatter.location'))
      return {
        attributes: {
          place: title,
          url: get(node, 'fields.slug'),
          lastVisitDate: get(node, 'frontmatter.date'),
          excerpt: get(node, 'excerpt'),
          thumbnailImage: thumbnailImage,
        },
        geometry: {
          type: 'point',
          x: location.coordinates[0],
          y: location.coordinates[1]
        }
      }
    })
    return features;
  }

  render() {

    const features = this.getEsriFeatures();
    const fields = ['place', 'lastVisitDate', 'thumbnailImage']
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')

    return (
      <Layout location={this.props.location}>
        <Seo
          title="Map"
          keywords={keywords}
        />
        <div css={themeStyles.textPadding}>
          <h2 css={{marginBottom: '5px'}}>Where Have We Been?</h2>
          <Map features={features} />
        </div>
        <Share slug="/map" title={siteTitle} excerpt={siteDescription} />
      </Layout>
    )
  }
}

export const mapQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
    allMarkdownRemark(filter: {fields: {slug: { regex: "/places/"}}}) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM YYYY")
            title
            featuredImage {
              childImageSharp {
                fixed(width: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            location
            published
          }
        }
      }
    }
  }
`
export default MapComponent
