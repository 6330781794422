import React from 'react'
import { loadModules } from 'esri-loader';
import { css } from 'glamor'

import { presets } from '../utils/theme'
import colors from '../utils/colors'

// map initialization options
const mapOptions = {
  basemap: 'dark-gray'
}

const viewOptions = {
  zoom: 1,
  center: [0, 0],
  constraints: {
    minZoom: 1,
    maxZoom: 5,
  },
  ui: {
    components: ['zoom'],
    padding: { left: 10, top: 10, right: 10, bottom: 0 }
  },
  popup: {
    //actions: [],
    collapseEnabled: false,
  }
}

const sceneOptions = {
  constraints: {
    altitude: {
      min: 2000000
    }
  },
  ui: {
    components: ['zoom', 'navigation-toggle', 'compass'],
  },
}

const popupTemplate = {
  title: "<a href='{url}'><b>{place}</b></a>",
  content: "<a href='{url}'><img src='{thumbnailImage}' style='float:left;margin-right:10px;margin-bottom:5px;'></a><div style='padding-top:5px;font-size:0.9em;'>We last visited {place} in {lastVisitDate}.</div>"
};

const fields = [
  {
    name: 'place',
    type: 'string',
  },
  {
    name: 'url',
    type: 'string',
  },
  {
    name: 'excerpt',
    type: 'string',
  },
  {
    name: 'lastVisitDate',
    type: 'string',
  },
  {
    name: 'thumbnailImage',
    type: 'string',
  },
]

const renderer = {
  type: "simple",
  symbol: {
    type: "picture-marker",
    url: ' http://static.arcgis.com/images/Symbols/Shapes/BluePin2LargeB.png',
    height: 36,
    width: 32,
    yoffset: 9,
  },
};

const searchOptions = {
  includeDefaultSources: false,
  locationEnabled: false,
}

const styles = {
  map: {
    position: 'relative',
    backgroundColor: '#222327',
    border: '1px solid ' + colors.grey,
    height: '60vh',
    [presets.Large]: {
      height: 'calc(100vh - 170px)',
    },
    width: '100%',
    '& img': {
      marginBottom: 0,
    },
  },
  view: {
    width: '100%',
    height: '100%',
  },
  infoDiv: {
    position: 'absolute',
    top: '10px',
    left: '55px',
    '& input': {
      border: 'none',
      boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 2px',
    }
  },
}

css.insert('.esri-popup--is-docked {margin: 10px !important} .esri-popup__main-container {max-width: 100% !important;width: 280px !important;}')

class Map extends React.Component {
  constructor(props) {
    super(props);
    // a ref to the DOM node where we want to create the map
    this.viewNode = React.createRef();
  }

  loadMap() {
    const { features } = this.props

    this.switchViewButton = document.getElementById('switch-view-btn');
    this.widgets = [];

    return loadModules(
      [
        'esri/Map',
        'esri/views/MapView',
        'esri/views/SceneView',
        'esri/Graphic',
        'esri/layers/FeatureLayer',
      ],
      { css: true }
    ).then(([
        Map,
        MapView,
        SceneView,
        Graphic,
        FeatureLayer,
      ]) => {

        // create the Map
        let map = new Map(mapOptions);
        this.map = map;

        // show the map at the element
        this.mapView = new MapView({
          map,
          container: this.viewNode.current,
          ...viewOptions,
        });
        this.activeView = this.mapView;
        this.setPopupOptions();

        this.sceneView = new SceneView({
          map,
          ...viewOptions,
          ...sceneOptions,
        });

      const graphics = features.map(function(feature) {
        return Graphic.fromJSON(feature);
      });

      const featureLayer = new FeatureLayer({
        popupTemplate: popupTemplate,
        objectIdField: 'FId',
        fields: fields,
        source: graphics,
        renderer
      });

      this.map.add(featureLayer);

      // wait for the view to load
      return this.mapView.when(() => {
        // prevent zooming with the mouse-wheel
        this.mapView.on('mouse-wheel', function(evt) {
          evt.stopPropagation();
        });
        this.loadWidgets(featureLayer);
        //this.activeView.goTo(graphics);
      });
    });
  }

  loadWidgets(featureLayer) {
    return loadModules(
      [
        'esri/widgets/Expand',
        'esri/widgets/Search',
        'esri/widgets/Fullscreen',
      ],
    ).then(([
        Expand,
        Search,
        Fullscreen,
      ]) => {

        const mapFullscreen = new Fullscreen({
          view: this.mapView
        });
        this.mapView.ui.add(mapFullscreen, "top-left");

        const sceneFullscreen = new Fullscreen({
          view: this.sceneView
        });
        this.sceneView.ui.add(sceneFullscreen, "top-left");

        const mapSearch = new Search({
          view: this.mapView,
          container: document.createElement('div'),
          sources: [
            {
              layer: featureLayer,
              searchFields: ["place"],
              displayField: "place",
              exactMatch: false,
              outFields: ["place",],
              name: "Places",
              placeholder: "example: New Zealand"
            },
          ],
          ...searchOptions,
        });

        const mapSearchExpand = new Expand({
          expandIconClass: "esri-icon-search",
          expandTooltip: 'Search for a Place',
          view: this.mapView,
          content: mapSearch.domNode,
        })
        this.mapView.ui.add(mapSearchExpand, "top-right");

        const sceneSearch = new Search({
          view: this.sceneView,
          container: document.createElement('div'),
          sources: [
            {
              layer: featureLayer,
              searchFields: ["place"],
              displayField: "place",
              exactMatch: false,
              outFields: ["place",],
              name: "Places",
              placeholder: "example: New Zealand"
            },
          ],
          ...searchOptions,
        });

        const sceneSearchExpand = new Expand({
          expandIconClass: "esri-icon-search",
          expandTooltip: 'Search for a Place',
          view: this.sceneView,
          content: sceneSearch.domNode,
        })
        this.sceneView.ui.add(sceneSearchExpand, "top-right");

        this.switchViewButton.style.display = 'block'
      }
    )
  }

  switchView() {
    var is3D = this.activeView.type === "3d";
    var activeViewpoint = this.activeView.viewpoint.clone();

    // remove the reference to the container for the previous view
    this.activeView.container = null;

    if (is3D) {
      // if the input view is a SceneView, set the viewpoint on the
      // mapView instance. Set the container on the mapView and flag
      // it as the active view
      this.mapView.viewpoint = activeViewpoint;
      this.mapView.container = this.viewNode.current;
      this.activeView = this.mapView;
      this.switchViewButton.value = "3D";
    } else {
      this.sceneView.viewpoint = activeViewpoint;
      this.sceneView.container = this.viewNode.current;
      this.activeView = this.sceneView;
      this.switchViewButton.value = "2D";
    }

    this.setPopupOptions()
  }

  setPopupOptions() {
    this.activeView.popup.set('dockOptions', {
      buttonEnabled: false,
      breakPoint: false,
      position: 'bottom-right',
    })
  }

  // react lifecycle methods
  // wait until after the component is added to the DOM before creating the map
  componentDidMount() {
    // create a map at this element's DOM node
    this.loadMap()
  }

  // destroy the map before this component is removed from the DOM
  componentWillUnmount() {
    if (this.mapView) {
      this.mapView.container = null;
      delete this._view;
    }
    if (this.sceneView) {
      this.sceneView.container = null;
      delete this.sceneView;
    }
  }

  render() {
    return (
      <div css={styles.map}>
        <div css={styles.view} ref={this.viewNode} />
        <div css={styles.infoDiv}>
          <input
            className='esri-component esri-widget--button esri-widget esri-interactive'
            type='button'
            id='switch-view-btn'
            value='3D'
            style={{display: 'none'}}
            onClick={this.switchView.bind(this)}
          />
        </div>
      </div>
    )
  }
}

export default Map
